.root {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--color-white);
  color: var(--color-black);
  font-size: 18px;
  text-decoration: none;

  border: 2px solid var(--color-white);
}

.root:hover,
.root:focus {
  border-color: var(--color-black);
  box-shadow: 0 0 5px var(--color-white);
}

.root:active {
  border-color: var(--color-red);
}

.small {
  font-size: 14px;
  padding: 5px 10px;
}
