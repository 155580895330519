.root {
  --transitionBounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: 50% 100%;
  transform: rotateX(90deg);
  animation: unfold 1s var(--transitionBounce) calc(1s * var(--appearDelay))
    forwards;
}

@keyframes unfold {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0);
  }
}
