.root {
  position: fixed;
  z-index: 60;

  width: 100vw;
  height: 1px;
  /* We think that positioning this based on top rather than bottom stops it jiggling
     on the iPhone... maybe */
  top: 100vh;
  left: 0;
  right: 0;
}

/* Hide foreground planetary detritus on small/landscape screens */
@media (max-height: 450px) {
  .root {
    display: none;
  }
}
