.root {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;

  margin: 60px 20px 0;
}

.header {
  flex-basis: 50%;
}

.intro {
  flex-basis: 50%;
  font-size: 22px;
}

.title {
  font-size: 72px !important;
  margin: 0 0 12px;
}

.byline {
  font-size: 22px;
  font-weight: 700;
}

.preview {
  margin: 40px 0 0;
  padding-right: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.bandCampPlayer {
  width: 100%;
  height: 324px;
}

.cta {
  margin: 20px 0;
  display: flex;
  gap: 5px;
}

@media (max-width: 700px) {
  .root {
    flex-direction: column;
    margin: 20px;
  }

  .header {
    margin: 0;
    flex-basis: auto;
    width: 100%;
  }

  .intro {
    margin: 20px 0;
    flex-basis: auto;
  }

  .title {
    font-size: 48px !important;
  }

  .byline {
    font-size: 16px;
  }

  .preview {
    margin: 20px 0 0;
    padding: 0;
  }

  .bandCampPlayer {
    width: 100%;
  }
}

@media (min-width: 1060px) {
  .root {
    max-width: 1000px;
    margin-inline: auto;
  }
}

/* Foreground Decorations */

.spacemanPosition {
  position: absolute;
  bottom: 60px;
  right: 15%;
}

.spaceman {
  transform-origin: 50% 100%;
  transform: rotateX(91deg);
  animation: sway 3s ease-in-out infinite alternate;
}

@keyframes sway {
  0% {
    transform: rotateZ(2deg);
  }
  100% {
    transform: rotateY(10deg);
  }
}

@media (max-width: 700px) {
  .spacemanPosition {
    bottom: 10px;
  }

  .spaceman {
    width: calc(131px / 1.2);
    height: calc(272px / 1.2);
  }
}

/* keep grounded on larger screens */
@media (min-width: 1100px) {
  .spacemanPosition {
    bottom: 10px;
  }
}
