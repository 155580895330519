.root p {
  margin: 15px 0;
}

.root p + p {
  margin: 15px 0 0;
}

.root h1 {
  font-size: 32px;
  margin: 10px 0;
}

.root h2 {
  font-size: 26px;
  margin: 10px 0;
}

.root h3 {
  font-size: 26px;
  margin: 0 0 10px;
}

.root a,
.root a:link,
.root a:visited {
  color: var(--color-brightGreen);
}

.root a:hover,
.root a:focus {
  color: var(--color-brightGreen);
  text-shadow: var(--color-brightGreen) 1px 1px 6px;
  text-decoration: underline;
}

.root ul,
.root ol {
  margin: 15px 0;
}

.root ul {
  list-style: disc;
  margin-left: 15px;
}

.root ol {
  list-style: number;
  margin-left: 30px;
}

.root blockquote {
  margin: 10px 0 10px 5px;
  padding: 5px 0 5px 15px;
  border-left: solid 3px var(--color-brightGreen);
}
